import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@/components/theme-provider';
import { Provider } from 'react-redux';
import router from '@/router';
import '@/index.css';
import store from './store/store'; 

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import publicRouter from '@/pages/public/public-router';

const servicesRootElement = document.getElementById('services-root');
const servicesUserRootElement = document.getElementById('services-user-root');

if (servicesRootElement) {
  ReactDOM.createRoot(servicesRootElement).render(
    <React.StrictMode>
      <ThemeProvider defaultTheme='light' storageKey='vite-ui-theme'>
        <RouterProvider router={router} />
        <ToastContainer />
      </ThemeProvider>
    </React.StrictMode>
  );
}

if (servicesUserRootElement) {
  ReactDOM.createRoot(servicesUserRootElement).render(
    <React.StrictMode>
      <Provider store={store}>  
        <ThemeProvider defaultTheme='light' storageKey='vite-ui-theme'>
          <RouterProvider router={publicRouter} />
          <ToastContainer />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
}
