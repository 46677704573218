import { createBrowserRouter } from 'react-router-dom';
import GeneralError from './pages/errors/general-error';
import NotFoundError from './pages/errors/not-found-error';

const router = createBrowserRouter([
  // Main routes
  {
    path: '/',
    lazy: async () => {
      const AppShell = await import('./components/app-shell');
      return { Component: AppShell.default };
    },
    errorElement: <GeneralError />,
    children: [
      {
        index: true,
        lazy: async () => ({
          Component: (await import('@/pages/admin/port')).default,
        }),
      },
      {
        path: 'port',
        lazy: async () => ({
          Component: (await import('@/pages/admin/port')).default,
        }),
      },
      {
        path: 'tracking',
        lazy: async () => ({
          Component: (await import('@/pages/admin/tracking')).default,
        }),
      },
      {
        path: 'shipment-status',
        lazy: async () => ({
          Component: (await import('@/pages/admin/shipment-status')).default,
        }),
      },
      {
        path: 'unstuffing-status',
        lazy: async () => ({
          Component: (await import('@/pages/admin/unstuffing-status')).default,
        }),
      },
      {
        path: 'sailing-schedules',
        lazy: async () => ({
          Component: (await import('@/pages/admin/sailing-schedules')).default,
        }),
      },
      {
        path: 'transshipments-sailing',
        lazy: async () => ({
          Component: (await import('@/pages/admin/transshipments-sailing-schedules')).default,
        }),
      },
      
      {
        path: 'lcl-rates',
        lazy: async () => ({
          Component: (await import('@/pages/admin/lcl-rates')).default,
        }),
      },
    ],
  },
  { path: '*', Component: NotFoundError },
],
{
  basename: '/admin-services',
});

export default router;
