// src/store/authSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export interface User {
    ID: number;
    user_login: string;
    user_email: string;
    display_name: string;
    phone_number: string;
    company_name: string;
    avatar: string;
}

export interface AuthState {  // <-- Exporting AuthState
  isLoggedIn: boolean;
  user: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isLoggedIn: false,
  user: null,
  loading: false,
  error: null,
};

export const checkLoginStatus = createAsyncThunk('auth/checkLoginStatus', async () => {
  const formData = new FormData();
  formData.append('action', 'check_user_logged_in');
  const response = await axios.post('/wp-admin/admin-ajax.php', formData);
  return response.data;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkLoginStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkLoginStatus.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success && action.payload.data.logged_in) {
          state.isLoggedIn = true;
          state.user = action.payload.data.user || null;
        } else {
          state.isLoggedIn = false;
          state.user = null;
        }
      })
      .addCase(checkLoginStatus.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.user = null;
        state.error = action.error.message || 'Failed to check login status';
      });
  },
});

export const { actions, reducer: authReducer } = authSlice;
export default authReducer;
