// src/store/store.ts

import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import authReducer, { AuthState } from './authSlice'; 

const store = configureStore({
  reducer: {
    auth: authReducer,
    // other reducers
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = {
  auth: AuthState;
  // add other slices' state types here
};

export default store;
