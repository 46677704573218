import { createBrowserRouter } from 'react-router-dom';
import GeneralError from '@/pages/errors/general-error';
import NotFoundError from '@/pages/errors/not-found-error';

const publicRouter = createBrowserRouter([
  // Main routes
  {
    path: '/',
    lazy: async () => {
      const PublicAppShell = await import('@/pages/public/public-app-shell');
      return { Component: PublicAppShell.default };
    },
    errorElement: <GeneralError />,
    children: [
      {
        index: true,
        lazy: async () => ({
          Component: (await import('@/pages/public/track-trace')).default,
        }),
      },
      {
        path: 'track-trace',
        lazy: async () => ({
          Component: (await import('@/pages/public/track-trace')).default,
        }),
      },

      {
        path: 'import-shipment-status',
        lazy: async () => ({
          Component: (await import('@/pages/public/import-shipment-status')).default,
        }),
      },

      {
        path: 'unstuffing-status',
        lazy: async () => ({
          Component: (await import('@/pages/public/unstuffing-status')).default,
        }),
      },

      {
        path: 'booking',
        lazy: async () => ({
          Component: (await import('@/pages/public/booking')).default,
        }),
      },
      {
        path: 'sailing-schedules',
        lazy: async () => ({
          Component: (await import('@/pages/public/sailing-schedules')).default,
        }),
      },

      {
        path: 'lcl-rates',
        lazy: async () => ({
          Component: (await import('@/pages/public/lcl-rates')).default,
        }),
      },

      {
        path: 'sign-up',
        lazy: async () => ({
          Component: (await import('@/pages/auth/sign-up')).default,
        }),
      },

      {
        path: 'sign-in',
        lazy: async () => ({
          Component: (await import('@/pages/auth/sign-in')).default,
        }),
      },
    ],
  },
  { path: '*', Component: NotFoundError },
],
{
  basename: '/e-services/',
});

export default publicRouter;
